import React from 'react';
import Image from 'next/image';
import notFoundImg from '@/assets/images/404_bg.png';
import Link from 'next/link';
import { SEOconfig } from 'next-seo.config';
import { notFoundPage } from '@/services/mock/seo';

export default function NotFoundPage() {
  return (
    <>
      <SEOconfig
        title="Page not found | Tạp Chí Trading"
        description={notFoundPage.desc}
        url=""
      />
      <div className="bg-[#E4F1F3]">
        <div className="container flex flex-col-reverse justify-around py-12 md:flex-row md:py-0">
          <div className="relative h-[400px] w-full md:h-[600px] md:w-[520px]">
            <Image
              className="object-cover"
              src={notFoundImg}
              fill
              alt="Page not found"
            />
          </div>

          <div className="font-header text-gray-variants-9 mb-16 flex flex-col items-center self-center md:mb-0 md:gap-y-4">
            <p className="text-[100px] font-bold leading-[150px] md:text-[150px]">
              404
            </p>
            <p className="mb-8 text-[24px] font-bold md:mb-0 md:text-[32px]">
              Page Not Found
            </p>
            <Link
              href="./"
              className="bg-primary2 md:text-sub2 rounded-[10px] px-10 py-3 text-xl font-semibold text-white"
            >
              Quay lại trang chủ
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
